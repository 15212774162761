<template>
  <v-select
    v-model="cliente"
    :items="clientes"
    clearable
    @click:clear="clearCliente"
    outlined
    dense
    item-text="nome_fantasia"
    item-value="cliente_id"
    hide-details
    label="Clientes"
  ></v-select>
</template>

<script>
import { fetchClientesColaborador } from "@/api/colaborador/clientes_colaborador.js";
import { mapState } from "vuex";

export default {
  name: "ClientesColaborador",

  data() {
    return {
      cliente: null,
      clientes: [],
      loading: false,
      loadingEmpresa: false,
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),
  },

  watch: {
    cliente() {
      console.log("CÇOEMTE", this.cliente);

      if (this.cliente) {
        this.$store.commit("Filtros/UPDATE_CLIENTES_COLABORADOR", this.cliente);
      }
    },
  },

  methods: {
    clearCliente() {
      this.$store.commit("Filtros/UPDATE_CLIENTES_COLABORADOR", null);
      this.cliente = null;
    },

    async getClientesColaboradors() {
      this.loading = true;
      await fetchClientesColaborador(this.colaborador_id)
        .then((response) => {
          this.clientes = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getClientesColaboradors();
  },
};
</script>

<style></style>
